import React, { Component } from "react"
import { Helmet } from "react-helmet"
import he from "he"
import { connect } from "react-redux"

import themeOptions from "../../../.brew-cache/theme.json"

import data from "../../../.brew-cache/data.json"
import Apple57 from "../../images/favicon/apple-icon-57x57.png"
import Apple60 from "../../images/favicon/apple-icon-60x60.png"
import Apple72 from "../../images/favicon/apple-icon-72x72.png"
import Apple76 from "../../images/favicon/apple-icon-76x76.png"
import Apple114 from "../../images/favicon/apple-icon-114x114.png"
import Apple120 from "../../images/favicon/apple-icon-120x120.png"
import Apple144 from "../../images/favicon/apple-icon-144x144.png"
import Apple152 from "../../images/favicon/apple-icon-152x152.png"
import Apple180 from "../../images/favicon/apple-icon-180x180.png"
import Android190 from "../../images/favicon/android-icon-192x192.png"
import Fav32 from "../../images/favicon/favicon-32x32.png"
import Fav96 from "../../images/favicon/favicon-96x96.png"
import Fav16 from "../../images/favicon/favicon-16x16.png"
import Ms144 from "../../images/favicon/ms-icon-144x144.png"
import Apple57II from "../../images/favicon-ii/apple-icon-57x57.png"
import Apple60II from "../../images/favicon-ii/apple-icon-60x60.png"
import Apple72II from "../../images/favicon-ii/apple-icon-72x72.png"
import Apple76II from "../../images/favicon-ii/apple-icon-76x76.png"
import Apple114II from "../../images/favicon-ii/apple-icon-114x114.png"
import Apple120II from "../../images/favicon-ii/apple-icon-120x120.png"
import Apple144II from "../../images/favicon-ii/apple-icon-144x144.png"
import Apple152II from "../../images/favicon-ii/apple-icon-152x152.png"
import Apple180II from "../../images/favicon-ii/apple-icon-180x180.png"
import Android190II from "../../images/favicon-ii/android-icon-192x192.png"
import Fav32II from "../../images/favicon-ii/favicon-32x32.png"
import Fav96II from "../../images/favicon-ii/favicon-96x96.png"
import Fav16II from "../../images/favicon-ii/favicon-16x16.png"
import Ms144II from "../../images/favicon-ii/ms-icon-144x144.png"
import { replaceMasks } from "../../services/masks"

const mapStateToProps = state => ({
  cookiesSettings: state.cookiesSettings,
})

const connector = connect(mapStateToProps, null)

class Meta extends Component {
  defaultImage = themeOptions.ThemeOptions.defaultImages.schemaAndShareImage.sourceUrl.replace(
    process.env.GATSBY_ADMIN_URL,
    process.env.GATSBY_FRONTEND_URL
  )

  getSchema() {
    const schemaOrgJSONLD = [
      {
        "@context": "http://schema.org",
        "@type": "LocalBusiness",
        name: data.name,
        legalName: data.name,
        openingHours: data.opening_hours,
        geo: {
          "@type": "GeoCoordinates",
          latitude: data.latitude,
          longitude: data.longitude,
        },
        description: "",
        slogan: "",
        address: {
          "@type": "PostalAddress",
          addressLocality: data.town,
          addressRegion: data.region,
          postalCode: data.postcode,
          streetAddress: data.address1,
        },
        telephone: data.telephone,
        email: data.email,
        image: this.defaultImage,
        url: data.url,
      },
    ]

    // Add any custom schema objects being passed through from the page
    if (this.props.meta.schema) {
      this.props.meta.schema.map(item => {
        return schemaOrgJSONLD.push(item)
      })
    }
    return JSON.stringify(schemaOrgJSONLD)
  }

  render() {
    const meta = replaceMasks(this.props.meta)

    return (
      <Helmet
        encodeSpecialCharacters={false}
        htmlAttributes={{ lang: meta.locale ? meta.locale : "en" }}
      >
        <title>
          {meta.metaTitle ? he.decode(meta.metaTitle) : this.props.title}
        </title>
        <html lang="en" />
        <meta name="description" content={meta.metaDescription} />

        {meta.canonical !== undefined ? (
          <link rel="canonical" href={data.url + "/" + meta.canonical} />
        ) : null}

        <meta name="docsearch:version" content="2.0" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
        />

        {/* Schema */}
        <script type="application/ld+json">{this.getSchema(data.url)}</script>

        {/* OpenGraph */}
        {meta.ogTitle ? (
          <meta property="og:url" content={data.url + this.props.path} />
        ) : null}
        {meta.ogTitle ? <meta property="og:type" content="website" /> : null}
        {meta.ogTitle ? <meta property="og:locale" content="en" /> : null}
        {meta.ogTitle ? (
          <meta property="og:site_name" content={data.name} />
        ) : null}
        {meta.ogTitle ? (
          <meta property="og:title" content={meta.ogTitle} />
        ) : null}
        {meta.ogDescription ? (
          <meta property="og:description" content={meta.ogDescription} />
        ) : null}
        {meta.ogImage ? (
          <meta property="og:image" content={meta.ogImage.sourceUrl} />
        ) : (
          <meta property="og:image" content={this.defaultImage} />
        )}

        {/* Twitter Card */}
        {meta.twitterTitle ? (
          <meta name="twitter:site" content={data.twitter_handle} />
        ) : null}
        {meta.twitterTitle ? (
          <meta name="twitter:title" content={meta.twitterTitle} />
        ) : null}
        {meta.twitterTitle ? (
          <meta name="twitter:description" content={meta.twitterDescription} />
        ) : null}
        {meta.twitterTitle ? (
          <meta name="twitter:card" content={meta.twitterCardType} />
        ) : null}
        {meta.twitterImage ? (
          <meta name="twitter:image" content={meta.twitterImage.sourceUrl} />
        ) : (
          <meta name="twitter:image" content={this.defaultImage} />
        )}

        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href={(this.props.ii === true && Apple57II) || Apple57}
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href={(this.props.ii === true && Apple60II) || Apple60}
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href={(this.props.ii === true && Apple72II) || Apple72}
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href={(this.props.ii === true && Apple76II) || Apple76}
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href={(this.props.ii === true && Apple114II) || Apple114}
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href={(this.props.ii === true && Apple120II) || Apple120}
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href={(this.props.ii === true && Apple144II) || Apple144}
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href={(this.props.ii === true && Apple152II) || Apple152}
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={(this.props.ii === true && Apple180II) || Apple180}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href={(this.props.ii === true && Android190II) || Android190}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={(this.props.ii === true && Fav16II) || Fav16}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href={(this.props.ii === true && Fav96II) || Fav96}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={(this.props.ii === true && Fav32II) || Fav32}
        />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta
          name="msapplication-TileImage"
          content={(this.props.ii === true && Ms144II) || Ms144}
        />
        <meta name="theme-color" content="#ffffff" />

        {this.props.cookiesSettings.targeting && (
          <script>{themeOptions.ThemeOptions.facebookPixelCode}</script>
        )}

        {/* GTM */}
        <script>
          {`
            <!-- Google Tag Manager -->
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-PNZ5XX2');
            <!-- End Google Tag Manager -->
          `}
        </script>
      </Helmet>
    )
  }
}

export default connector(Meta)
